import axios from "axios";

const host_path = process.env.MIX_HOST_PATH;

const config = {
    headers: { Authorization: `Bearer ${localStorage.user_token}` },
};

const urls = {
    auth: "/api/auth",
    statictics: "/api/statistics",
    transactions: "/api/transactions",
    stackHolders: "/api/stack-holders",
    projects: "/api/projects",
    calculationPeriods: "/api/calculation-periods",
    employeeVacations: "/api/employee-vacations",
    employees: "/api/employees",
    occupations: "/api/occupations",
    users: "/api/users",
};

export const auth = {
    login: (data) => {
        return axios.post(host_path + urls.auth + "/login", data);
    },
    logout: () => {
        return axios.get(host_path + urls.auth + "/logout", config);
    },
};

//transactions
export const transactions = {
    list: (filter = {}, page) => {
        const params = new URLSearchParams(filter, page);
        return axios.get(host_path + urls.transactions + "?page=" + page, {
            config,
            params,
        });
    },
    show: (id) => {
        return axios.get(host_path + urls.transactions + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.transactions, data, config);
    },
    update: (id, data) => {
        return axios.patch(
            host_path + urls.transactions + "/" + id,
            data,
            config
        );
    },
    delete: (id) => {
        return axios.delete(host_path + urls.transactions + "/" + id, config);
    },
    upload: (data) => {
        return axios.post(host_path + "/api/imports/upload", data, config);
    },
    parse: (data) => {
        return axios.post(host_path + "/api/imports/parse", data, config);
    },
    save: (data) => {
        return axios.post(host_path + "/api/imports/save", data, config);
    },
};

//calculation periods
export const calculationPeriods = {
    list: (hide_closed = false) => {
        return axios.get(
            host_path +
                urls.calculationPeriods +
                (hide_closed ? "?hide_closed=true" : ""),
            config
        );
    },
    close: (id) => {
        return axios.get(
            host_path + urls.calculationPeriods + "/" + id + "/close",
            config
        );
    },
    show: (id) => {
        return axios.get(
            host_path + urls.calculationPeriods + "/" + id,
            config
        );
    },
    create: (data) => {
        return axios.post(host_path + urls.calculationPeriods, data, config);
    },
    update: (id, data) => {
        return axios.patch(
            host_path + urls.calculationPeriods + "/" + id,
            data,
            {
                auth: {
                    username: username,
                    password: password,
                },
            }
        );
    },
    delete: (id) => {
        return axios.delete(
            host_path + urls.calculationPeriods + "/" + id,
            config
        );
    },
};

//employee vacations
export const employeeVacations = {
    list: () => {
        return axios.get(host_path + urls.employeeVacations, config);
    },
    show: (id) => {
        return axios.get(host_path + urls.employeeVacations + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.employeeVacations, data, config);
    },
    update: (id, data) => {
        return axios.patch(host_path + urls.employeeVacations + "/" + id, data, config);
    },
    delete: (id) => {
        return axios.delete(host_path + urls.calculationPeriods + "/" + id, config);
    },
};

//users
export const users = {
    roles: () => {
        return axios.get(host_path + urls.users + "/roles", config);
    },
    list: () => {
        return axios.get(host_path + urls.users, config);
    },
    show: (id) => {
        return axios.get(host_path + urls.users + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.users, data, config);
    },
    update: (id, data) => {
        return axios.patch(host_path + urls.users + "/" + id, data, config);
    },
    delete: (id) => {
        return axios.delete(host_path + urls.users + "/" + id, config);
    },
};

//stack holders
export const stackHolders = {
    list: () => {
        return axios.get(host_path + urls.stackHolders, config);
    },
    getDividends: (calculation_period_id) => {
        return axios.get(
            host_path +
                urls.stackHolders +
                "/get-dividends?calculation_period_id=" +
                calculation_period_id,
            config
        );
    },
    show: (id) => {
        return axios.get(host_path + urls.stackHolders + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.stackHolders, data, config);
    },
    update: (id, data) => {
        return axios.patch(
            host_path + urls.stackHolders + "/" + id,
            data,
            config
        );
    },
    delete: (id) => {
        return axios.delete(host_path + urls.stackHolders + "/" + id, config);
    },
};

//projects
export const projects = {
    list: () => {
        return axios.get(host_path + urls.projects, config);
    },
    getProfit: (calculation_period_id) => {
        return axios.get(
            host_path +
                urls.projects +
                "/get-profit?calculation_period_id=" +
                calculation_period_id,
            config
        );
    },
    show: (id) => {
        return axios.get(host_path + urls.projects + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.projects, data, config);
    },
    update: (id, data) => {
        return axios.patch(host_path + urls.projects + "/" + id, data, config);
    },
    delete: (id) => {
        return axios.delete(host_path + urls.projects + "/" + id, config);
    },
};

//employees
export const employees = {
    list: () => {
        return axios.get(host_path + urls.employees, config);
    },
    listActive: () => {
        return axios.get(host_path + urls.employees + "?active=true", config);
    },
    getEmployeesExpenses: (calculation_period_id) => {
        return axios.get(
            host_path +
                urls.employees +
                "/get-employee-expenses/?calculation_period_id=" +
                calculation_period_id,
            config
        );
    },
    show: (id) => {
        return axios.get(host_path + urls.employees + "/" + id, config);
    },
    create: (data) => {
        return axios.post(host_path + urls.employees, data, config);
    },
    update: (id, data) => {
        return axios.patch(host_path + urls.employees + "/" + id, data, config);
    },
    delete: (id) => {
        return axios.delete(host_path + urls.employees + "/" + id, config);
    },
};

//occupations
export const occupations = {
    get: (calculation_period_id) => {
        return axios.get(
            host_path +
                urls.occupations +
                "?calculation_period_id=" +
                calculation_period_id,
            config
        );
    },
    save: (data) => {
        return axios.post(host_path + urls.occupations, data, config);
    },
};
