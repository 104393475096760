<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Транзакции</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-button v-if="$can('transactions.import')" @click="importTransactions" type="warning">Импорт
                    </el-button>
                    <el-button v-if="$can('transactions.write')" @click="newTransaction()" type="success"
                        icon="el-icon-plus">Создать</el-button>
                </el-col>
            </el-row>
        </el-header>

        <el-main>

            <el-card class="box-card">
                <el-form ref="form" :model="filterFields" label-width="160px">
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="Расчетный период">
                                <el-select v-model="filterFields.calculation_period">
                                    <el-option label="" value=""></el-option>
                                    <el-option v-for="item in calculationPeriods" :key="item.id"
                                        :label="item.period_short + ((item.closed) ? ' - закрыт' : '')"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Фин. тип">
                                <el-radio v-model="filterFields.financial_type" label="out">Expense</el-radio>
                                <el-radio v-model="filterFields.financial_type" label="in">Income</el-radio>
                            </el-form-item>
                        </el-col>

                        <el-col :span="6">
                            <el-form-item label="Дата от">
                                <el-date-picker value-format="yyyy-MM-dd" v-model="filterFields.date_from" type="date"
                                    placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Дата до">
                                <el-date-picker value-format="yyyy-MM-dd" v-model="filterFields.date_to" type="date"
                                    placeholder="Pick a day">
                                </el-date-picker>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="Тип">
                                <el-select v-model="filterFields.type">
                                    <el-option label="Other" value="other"></el-option>
                                    <el-option label="Tech" value="tech"></el-option>
                                    <el-option label="Staff" value="staff"></el-option>
                                    <el-option label="Contragents" value="contragents"></el-option>
                                    <el-option label="Office" value="office"></el-option>
                                    <el-option label="Marketing" value="marketing"></el-option>
                                    <el-option label="Taxes" value="taxes"></el-option>
                                    <el-option label="Dividends" value="dividends"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Проект">
                                <el-select v-model="filterFields.project">
                                    <el-option label="" value=""></el-option>
                                    <el-option v-for="item in projects" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Сотрудник">
                                <el-select v-model="filterFields.employee">
                                    <el-option label="" value=""></el-option>
                                    <el-option v-for="item in employees" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Основатель">
                                <el-select v-model="filterFields.stack_holder">
                                    <el-option label="" value=""></el-option>
                                    <el-option v-for="item in stackHolders" :key="item.id" :label="item.name"
                                        :value="item.id"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span="6">
                            <el-form-item label="Сумма от">
                                <el-input v-model="filterFields.amount_from"></el-input>
                            </el-form-item>
                        </el-col>
                        <el-col :span="6">
                            <el-form-item label="Сумма до">
                                <el-input v-model="filterFields.amount_to"></el-input>
                            </el-form-item>
                        </el-col>

                        <el-col :span="12">
                            <el-form-item>
                                <el-button type="primary" @click="applyFilter()">Применить</el-button>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
            </el-card>

            <el-table :data="data" v-if="$can('transactions.read')" show-summary="true">
                <el-table-column prop="financial_type" label="" width="50">
                    <template slot-scope="scope">
                        <div style="font-size: 20px">
                            <i v-if="scope.row.financial_type == 'out'" class="el-icon-top-right"
                                style="color: red"></i>
                            <i v-if="scope.row.financial_type == 'in'" class="el-icon-bottom-right"
                                style="color: green"></i>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="calculation_period.period_short" label="Period">
                </el-table-column>
                <el-table-column prop="date" label="Date">
                </el-table-column>
                <el-table-column prop="amount" label="Amount">
                </el-table-column>
                <el-table-column prop="description" label="Description">
                </el-table-column>
                <el-table-column prop="contragent" label="Contragent">
                </el-table-column>
                <el-table-column prop="type" label="Type">
                </el-table-column>
                <el-table-column prop="project.name" label="Project">
                </el-table-column>
                <el-table-column prop="money_source" label="Money source">
                </el-table-column>
                <el-table-column prop="stack_holder.name" label="Founder">
                </el-table-column>
                <el-table-column prop="employee.name" label="Employee">
                </el-table-column>
                <el-table-column label="Actions">
                    <template slot-scope="scope">
                        <el-button-group style="font-size: 20px">
                            <i @click="editTransaction(scope.$index)" class="el-icon-edit" style="color: blue"></i>
                            <el-popconfirm @confirm="deleteTransaction(scope.$index)" title="Точно хотите удалить?"
                                confirm-button-text="Да" cancel-button-text="Нет">
                                <i slot="reference" style="color: red" class="el-icon-delete"></i>
                            </el-popconfirm>
                        </el-button-group>
                    </template>
                </el-table-column>
                <infinite-loading v-if="nextPage" slot="append" @infinite="getData">
                </infinite-loading>
            </el-table>

            <el-dialog title="Создать/Изменить" :visible.sync="createDialogVisible">
                <el-form :model="transactionObject" label-width="160px">
                    <el-form-item label="In/Out">
                        <el-radio v-model="transactionObject.financial_type" label="out">Expense</el-radio>
                        <el-radio v-model="transactionObject.financial_type" label="in">Income</el-radio>
                    </el-form-item>
                    <el-form-item label="Amount">
                        <el-input v-model="transactionObject.amount" autocomplete="off"></el-input>
                    </el-form-item>
                    <el-form-item label="Description">
                        <el-input v-model="transactionObject.description" autocomplete="off" type="textarea" autosize>
                        </el-input>
                    </el-form-item>
                    <el-form-item label="Contragent">
                        <el-input v-model="transactionObject.contragent"></el-input>
                    </el-form-item>
                    <el-form-item label="Date">
                        <el-input v-model="transactionObject.date"></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="Date">
                        <el-date-picker v-model="transactionObject.date" type="datetime" placeholder="Select date and time" value-format="YYYY-MM-DD h:m:s">
                        </el-date-picker>
                    </el-form-item> -->
                    <el-form-item label="Type">
                        <el-select v-model="transactionObject.type">
                            <el-option label="Other" value="other"></el-option>
                            <el-option label="Tech" value="tech"></el-option>
                            <el-option label="Staff" value="staff"></el-option>
                            <el-option label="Contragents" value="contragents"></el-option>
                            <el-option label="Office" value="office"></el-option>
                            <el-option label="Marketing" value="marketing"></el-option>
                            <el-option label="Taxes" value="taxes"></el-option>
                            <el-option label="Dividends" value="dividends"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Money Source">
                        <el-select v-model="transactionObject.money_source">
                            <el-option label="Account" value="account"></el-option>
                            <el-option label="Founder" value="stack_holder"></el-option>
                            <el-option label="Employee" value="employee"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Employee"
                        v-if="(transactionObject.type == 'staff') || (transactionObject.money_source == 'employee')">
                        <el-select v-model="transactionObject.employee_id">
                            <el-option v-for="item in employees" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Founder"
                        v-if="(transactionObject.money_source == 'stack_holder') || (transactionObject.type == 'dividends')">
                        <el-select v-model="transactionObject.stack_holder_id">
                            <el-option v-for="item in stackHolders" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>jr
                    </el-form-item>
                    <el-form-item label="Project">
                        <el-select v-model="transactionObject.project_id">
                            <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Calculation Period">
                        <el-select v-model="transactionObject.calculation_period_id">
                            <el-option v-for="item in openCalculationPeriods" :key="item.id" :label="item.period_short"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="createDialogVisible = false">Cancel</el-button>
                    <el-button type="primary" @click="saveTransaction()">Save</el-button>
                </span>
            </el-dialog>

            <el-dialog title="Импорт" :visible.sync="importDialogVisible" width="50%">
                <el-form :model="importObject" label-width="200px">
                    <el-form-item label="Type">
                        <template>
                            <el-radio v-model="importObject.type" label="api">API</el-radio>
                            <el-radio v-model="importObject.type" label="file">File</el-radio>
                        </template>

                    </el-form-item>

                    <el-form-item label="File" v-if="importObject.type == 'file'">
                        <el-upload action="" :http-request="uploadImportFile" :show-file-list="true"
                            :limit="1">
                            <el-button size="small" type="primary">Click to upload</el-button>
                            <div slot="tip" class="el-upload__tip">
                                1S exchange file
                            </div>
                        </el-upload>

                    </el-form-item>


                    <el-form-item label="Account" v-if="importObject.type == 'api'">
                        <el-select v-model="importObject.account">
                            <el-option label="Простые платежи - Тиньков - Основной" value="40702810710000011071"></el-option>
                            <el-option label="Простые платежи - Тиньков - b2b" value="40702810810001643131"></el-option>
                            <el-option label="Мигрант сервис - Точка" value="40702810701500187763"></el-option>
                            <el-option label="Технологии роста - Точка" value="40702810907500011824"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Dates" v-if="importObject.type == 'api'">
                        <el-date-picker v-model="importObject.date" type="daterange" range-separator="-"
                            start-placeholder="From" end-placeholder="To" value-format="yyyy-MM-dd" name="from">
                        </el-date-picker>
                    </el-form-item>
                    <el-form-item label="Default project">
                        <el-select v-model="importObject.default_project_id">
                            <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id">
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="Default calculation period">
                        <el-select v-model="importObject.default_calculation_period_id">
                            <el-option v-for="item in openCalculationPeriods" :key="item.id" :label="item.period_short"
                                :value="item.id"></el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelImport()">Cancel</el-button>
                    <el-button type="primary" @click="parseTransactions()">Upload</el-button>
                </span>
            </el-dialog>

            <el-dialog title="Импорт" :visible.sync="importTableVisible" width="90%">
                <el-table :data="importedTransactions" style="width: 100%" height="400">
                    <el-table-column label="" width="40">
                        <template slot-scope="scope">
                            <el-button-group style="font-size: 20px">
                                <i @click="deleteRowFromImport(scope.$index)" style="color: red"
                                    class="el-icon-delete"></i>
                            </el-button-group>
                        </template>
                    </el-table-column>
                    <el-table-column prop="financial_type" label="" width="30">
                        <template slot-scope="scope">
                            <div style="font-size: 20px">
                                <i v-if="scope.row.financial_type == 'out'" class="el-icon-top-right"
                                    style="color: red"></i>
                                <i v-if="scope.row.financial_type == 'in'" class="el-icon-bottom-right"
                                    style="color: green"></i>
                            </div>
                        </template>
                    </el-table-column>
                    <el-table-column label="Period">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.calculation_period_id">
                                <el-option v-for="item in openCalculationPeriods" :key="item.id"
                                    :label="item.period_short" :value="item.id"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Type">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.type">
                                <el-option label="Other" value="other"></el-option>
                                <el-option label="Tech" value="tech"></el-option>
                                <el-option label="Staff" value="staff"></el-option>
                                <el-option label="Contragents" value="contragents"></el-option>
                                <el-option label="Office" value="office"></el-option>
                                <el-option label="Marketing" value="marketing"></el-option>
                                <el-option label="Taxes" value="taxes"></el-option>
                                <el-option label="Dividends" value="dividends"></el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Employee">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.employee_id" v-if="scope.row.type == 'staff'">
                                <el-option v-for="item in employees" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Founder">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.stack_holder_id" v-if="scope.row.type == 'dividends'">
                                <el-option v-for="item in stackHolders" :key="item.id" :label="item.name"
                                    :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column label="Project">
                        <template slot-scope="scope">
                            <el-select v-model="scope.row.project_id">
                                <el-option v-for="item in projects" :key="item.id" :label="item.name" :value="item.id">
                                </el-option>
                            </el-select>
                        </template>
                    </el-table-column>
                    <el-table-column prop="date" label="Date">
                    </el-table-column>
                    <el-table-column prop="description" label="Description">
                    </el-table-column>
                    <el-table-column prop="contragent" label="Contragent">
                    </el-table-column>
                    <el-table-column prop="amount" label="Amount" width="120">
                    </el-table-column>
                </el-table>
                <span slot="footer" class="dialog-footer">
                    <el-button @click="cancelImport()">Cancel</el-button>
                    <el-button type="primary" @click="saveTransactions()">Save</el-button>
                </span>
            </el-dialog>
        </el-main>
    </el-container>
</template>

<script>
import { transactions } from "../../api_connectors";
import { projects } from "../../api_connectors";
import { stackHolders } from "../../api_connectors";
import { employees } from "../../api_connectors";
import { calculationPeriods } from "../../api_connectors";
import InfiniteLoading from 'vue-infinite-loading';
var moment = require('moment');

export default {
    name: "TransactionsIndex",
    components: {
        InfiniteLoading,
    },
    data() {
        return {
            filterFields: {},
            nextPage: 1,
            data: [],
            stackHolders: [],
            calculationPeriods: [],
            openCalculationPeriods: [],
            employees: [],
            projects: [],
            createDialogVisible: false,
            importDialogVisible: false,
            importTableVisible: false,
            transactionObject: {
                external_id: null,
                amount: 0,
                description: "",
                contragent: '',
                date: "",
                project_id: null,
                calculation_period_id: null,
                money_source_id: null,
                type: "other",
                money_source: "account",
                financial_type: "out"
            },
            importObject: {
                type: 'api',
                path: '',
                account: null,
                date: null,
                default_project_id: null,
                default_calculation_period_id: null,
            },
            importedTransactions: [],
        };
    },
    mounted() {
        //this.getData();
        this.getEnums();
    },
    methods: {
        applyFilter() {
            this.data = [];
            this.nextPage = null;
            this.getData();
        },
        getData($state = null) {
            let activeFilter = {};
            for (let key in this.filterFields) {
                if (this.filterFields[key] != null && this.filterFields[key] != '') {
                    activeFilter[key] = this.filterFields[key]
                }
            }
            transactions
                .list(activeFilter, this.nextPage)
                .then((response) => {
                    this.data = this.data.concat(response.data.data)
                    this.nextPage = (response.data.next_page_url) ? response.data.current_page + 1 : null
                    if ($state) $state.loaded();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });


        },
        getEnums() {
            let promises = [];
            promises.push(stackHolders.list());
            promises.push(calculationPeriods.list());
            promises.push(employees.listActive());
            promises.push(projects.list());
            promises.push(calculationPeriods.list(true));


            Promise.all(promises)
                .then((response) => {
                    this.stackHolders = response[0].data;
                    this.calculationPeriods = response[1].data;
                    this.calculationPeriods.forEach(element => {
                        if (element.period_short == moment().subtract(1, 'month').format('MM.YYYY')) {
                            this.calculationPeriod = element.id
                        }
                    });
                    this.getData();
                    this.employees = response[2].data;
                    this.projects = response[3].data;
                    this.openCalculationPeriods = response[4].data;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        newTransaction() {
            this.transactionObject = {
                external_id: null,
                amount: 0,
                description: "",
                contragent: "",
                date: "",
                project_id: null,
                calculation_period_id: null,
                money_source_id: null,
                type: "other",
                money_source: "account",
                financial_type: "out"
            };

            this.createDialogVisible = true;
        },
        editTransaction($index) {
            this.transactionObject = this.data[$index];

            this.createDialogVisible = true;
        },
        saveTransaction() {
            this.$loading();
            let result =
                this.transactionObject.id > 0
                    ? transactions.update(
                        this.transactionObject.id,
                        this.transactionObject
                    )
                    : transactions.create(this.transactionObject);
            result
                .then((response) => {
                    this.$loading().close();
                    this.$message({
                        message: "Успешное сохранение!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                    this.data = [];
                    this.getData();
                    this.createDialogVisible = false;
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        deleteTransaction($index) {
            this.$loading();
            transactions
                .delete(this.data[$index].id)
                .then((response) => {
                    this.data = [];
                    this.getData();
                    this.$loading().close();
                    this.$message({
                        message: "Успешное удаление!",
                        type: "success",
                        duration: 3000,
                        showClose: true,
                    });
                })
                .catch((error) => {
                    this.$loading().close();
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        cancelImport() {
            this.importObject = {
                type: 'api',
                path: '',
                account: null,
                date: null,
                default_project_id: null,
                default_calculation_period_id: null,
            };
            this.importDialogVisible = false;
            this.importTableVisible = false;
        },
        importTransactions() {
            this.importDialogVisible = true;
            this.createDialogVisible = false;
            this.importTableVisible = false;
        },
        uploadImportFile(params) {
            var formData = new FormData();
            formData.append("file", params.file);


            transactions
                .upload(formData)
                .then((response) => {
                    this.importObject.path = response.data.path;
                    //console.log(response.data);
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить файл: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        parseTransactions() {
            transactions
                .parse(this.importObject)
                .then((response) => {
                    this.importedTransactions = response.data;
                    this.importDialogVisible = false;
                    this.createDialogVisible = false;
                    this.importTableVisible = true;
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        saveTransactions() {
            this.$loading();
            transactions
                .save({ transactions: this.importedTransactions })
                .then((response) => {
                    this.getData();
                    this.importDialogVisible = false;
                    this.createDialogVisible = false;
                    this.importTableVisible = false;
                    this.$loading().close();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось сохранить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                    this.$loading().close();
                });
        },
        deleteRowFromImport(index) {
            this.importedTransactions.splice(index, 1);
        }
    },
};
</script>

<style scoped></style>
