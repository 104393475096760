<template>
    <el-container>
        <el-header>
            <el-row>
                <el-col :span="6">
                    <span style="font-size: 18px">Дивиденды</span>
                </el-col>
                <el-col :span="18" align="right">
                    <el-select v-model="calculationPeriod" @change="getData">
                        <el-option v-for="item in calculationPeriods" :key="item.id" :label="item.period_short"
                            :value="item.id"></el-option>
                    </el-select>
                </el-col>
            </el-row>
        </el-header>

        <el-main>

            <table width="100%" border="0">
                <tr>
                    <td v-for="item in dividendsData" :key="item.id" style="vertical-align: top;">
                        {{ item.name }}
                        <el-table :data="item.data" row-key="id" border>
                            <el-table-column prop="name" label="Название">
                            </el-table-column>
                            <el-table-column prop="sum" label="Сумма">
                            </el-table-column>
                        </el-table>
                    </td>
                </tr>
            </table>





        </el-main>
    </el-container>
</template>

<script>
import { calculationPeriods } from "../../api_connectors";
import { stackHolders } from "../../api_connectors";
var moment = require('moment');

export default {
    name: "ProfitabilityIndex",
    data() {
        return {
            calculationPeriods: [],
            calculationPeriod: null,
            dividendsData: null,
        };
    },
    mounted() {
        this.getCalculationPeriods();
    },
    methods: {
        getCalculationPeriods() {
            calculationPeriods
                .list()
                .then((response) => {
                    this.calculationPeriods = response.data;
                    this.calculationPeriods.forEach(element => {
                        if (element.period_short == moment().subtract(1, 'month').format('MM.YYYY')) {
                            this.calculationPeriod = element.id
                        }
                    });
                    this.getData();
                })
                .catch((error) => {
                    this.$message({
                        message: "Не удалось загрузить данные: " + error,
                        type: "error",
                        duration: 5000,
                        showClose: true,
                    });
                });
        },
        getData() {
            if (this.calculationPeriods.find(x => x.id === this.calculationPeriod).closed) {
                console.log('getting from saved data');
                this.dividendsData = this.calculationPeriods.find(x => x.id === this.calculationPeriod).founders_data
            } else {
                console.log('getting from api');
                stackHolders
                    .getDividends(this.calculationPeriod)
                    .then((response) => {
                        this.dividendsData = response.data;
                        //console.log(this.dividendsData);
                    })
                    .catch((error) => {
                        this.$message({
                            message: "Не удалось загрузить данные: " + error,
                            type: "error",
                            duration: 5000,
                            showClose: true,
                        });
                    });
            }

        },

    },
};
</script>

<style scoped></style>
